import { useEffect, useState } from 'react';

import { Route, Routes } from 'react-router-dom';
// import Header from '../Header/Header';
// import Main from '../Main/Main';
// import StepOrder from '../StepOrder/StepOrder';
// import UniqueProposition from '../UniqueProposition/UniqueProposition';
// import OurServices from '../OurServices/OurServices';
// import Tariffs from '../Tariffs/Tariffs';
// import PriceIncluded from '../PriceIncluded/PriceIncluded';
// import WorkExemple from '../WorkExemple/WorkExemple';
// import Reasons from '../Reasons/Reasons';
// import Reviews from '../Reviews/Reviews';
// import Faq from '../Faq/Faq';
// import Modal from '../Modal/Modal';
// import Footer from '../Footer/Footer';

import MainPage from '../../pages/Main/Main';
import Thanks from '../../pages/Thanks/Thanks';


import './App.scss';
// import { Helmet } from 'react-helmet';

function App() {

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);
  

  return (
    <>
    
      {/* <!-- Meta Pixel Code --> */}
        {/* <script>
            !function(f,b,e,v,n,t,s) 
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1055364738832027');
            fbq('track', 'PageView');
          </script>
          <noscript>
            <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1055364738832027&ev=PageView&noscript=1"
          />
          </noscript> */}
{/* <!-- End Meta Pixel Code --> */}
   

      <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="thanks" element={<Thanks/>} />
            
            {/* <Route path="*" element={<NotFoundPage />} /> */}
          </Routes>
    </>
  );
}

export default App;
