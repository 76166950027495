import { useEffect, useState } from "react";
import Faq from "../../components/Faq/Faq";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import ForWhom from "../../components/ForWhom/ForWhom";
import Rewies from "../../components/Rewies/Rewies";
import Timer from "../../components/Timer/Timer";
import FirstDay from "../../components/FirstDay/FirstDay";
import YouWon from "../../components/YouWon/YouWon";

import './Main.scss';
import { Helmet } from "react-helmet";


const MainPage = () => {
  const [modal, setModal] = useState(false); 
  const [currentProposal, setCurrentProposal] = useState([]);
  

  const currentDate = new Date();
  const currentDayOfMonth = currentDate.getDate();

  
  useEffect(() => {
    if (localStorage.getItem('surprise1')) {
      setCurrentProposal(state => [...state, JSON.parse(localStorage.getItem('surprise1'))])
    }

    if (localStorage.getItem('surprise2')) {
      setCurrentProposal(state => [...state, JSON.parse(localStorage.getItem('surprise2'))])
    }
  }, [])


  useEffect(() => {
    const savedDate = localStorage.getItem('savedDate');

    if (!savedDate) {
      localStorage.setItem('savedDate', new Date().toISOString());
      // console.log('test2');
      return
    }
    // console.log(isToday(new Date(savedDate)));
    // console.log(savedDate);
    // '2023-11-21T06:07:14.809Z'
    if (savedDate && !isToday(new Date(savedDate))) {
      
      // console.log('test1');

      localStorage.clear();
      localStorage.setItem('savedDate', new Date().toISOString());
      return
    } else {
      // console.log(1);
      // console.log(isToday(new Date(savedDate)));
      // console.log(savedDate);
    }

    function isToday(date) {
      // console.log(date);
      const today = new Date();
      // console.log(today);
      // console.log(date.getDate() === today.getDate() &&
      // date.getMonth() === today.getMonth() &&
      // date.getFullYear() === today.getFullYear());
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    }
  }, [])

  // ЗАПУСК ПЕРШОГО ДНЯ
  const renderFirstBlock = 
    currentDayOfMonth <= 21
    ? 
    <FirstDay/> 
    :
    <>
      <Main setCurrentProposal={setCurrentProposal} currentDay={currentDayOfMonth}/>
      <a id="youwon"></a>
      <YouWon proposal={currentProposal}/>
    </>

// запуск тамйеру
  const renderTimer = 
    currentDayOfMonth > 21
    ? 
    <Timer/> 
    :
    null

  return (
    <div className="app">
      <Helmet>

        {
          `<!-- Meta Pixel Code -->
          <script>
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '856084049253400');
            fbq('track', 'PageView');
          </script>
          <noscript><img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=856084049253400&ev=PageView&noscript=1"
          /></noscript>
          <!-- End Meta Pixel Code -->`
        }
      </Helmet>

      {/* {modal ? <Modal close={setModal} setSize={setSize} size={size}/> : null} */}
      {/* <img src={bgLine} alt="bg line" className='app__bgLine' /> */}
      <div className="app__container" >
       

        <Header modal={setModal}/>
        <FirstDay/> 
        
        {/* {renderFirstBlock} */}
        {/* <Main setCurrentProposal={setCurrentProposal} currentDay={currentDayOfMonth}/>
        <a id="youwon"></a>
        <YouWon proposal={currentProposal}/> */}
        
        <a id="forwhom"></a>
        <ForWhom/>
        <a id="reviews"></a>
        <Rewies/>
        {/* <Timer/> */}
        {renderTimer}
        <Faq/>
        <a id="contact"></a>
        <Footer/>
      </div>
    </div>
  )
}

export default MainPage;
