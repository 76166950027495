import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import leftPink from '../../image/footer/left-pink.webp';
import cloud from '../../image/main/cloud-right.webp';
import light from '../../image/you-win/light-right.webp';

import stars from '../../image/for-whom/for-who-background.webp';


import './Faq.scss';


const data = [
  {
    question: 'Что даст мне курс Astrodemy? ',
    answer: 'Курс даёт знания как тем, кто только начал свой путь в изучении восточной астрологии Джйотиш, так и опытным астрологам, желающим прокачать свои навыки и повысить квалификацию.'
  },
  {
    question: 'Подходит ли новичкам курс Astrodemy?',
    answer: 'Да! Обучение сделано максимально понятно и информация доносится человеческим языком. Также, есть закрытое комьюнити со студентами и поддержкой - там всегда можно задать любой вопрос и получить ответ.'
  },
  {
    question: 'Ограничен ли доступ к материалам?',
    answer: 'Нет! При полной оплате любого нашего курса у Вас будет доступ к нему навсегда.'
  },
  {
    question: 'Где посмотреть программу курсов?',
    answer: ['Напишите свой вопрос в нашу поддержку:', 'и мы вам быстро ответим!'],
    link: 'https://t.me/kali_shankar_support'
  },
  {
    question: 'Буду ли я успевать учиться?',
    answer: 'Да! Онлайн-уроки выходят только в курсе Astrodemy Plus и добавляются студентам с доступом навсегда. Остальные курсы записаны ранее и доступны к просмотры сразу после покупки. Каждый проходит обучение в своем темпе, но никто ничего не упускает.'
  },
  {
    question: 'Смогу ли я разобраться в астрологии, составить свою натальную карту и вывести свое Предназначение?',
    answer: 'Да, уже после прохождения Базового курса Astrodemy Вы поймете, как составлять натальную карту и разберетесь в методике выведения Предназначения.'
  },
  {
    question: 'Проверен ли на практике преподаваемый материал?',
    answer: 'Разумеется. Кали Шанкар прожил более 10 лет в Индии, провел более 15 000 консультаций и обучил более 5,000+ студентов'
  },
  {
    question: 'После какого курса можно начинать консультировать и будет ли сертификат?',
    answer: 'Чтобы консультировать людей вам нужно полностью пройти Базовый курс Astrodemy и по желанию - курс Astrodemy Plus. После окончания обучения и успешной автоматизированного теста вы получите сертификат.'
  },
]

const Faq = () => {

  const customClassesAccordion = {
    root: 'faq__accordion',
    rounded: 'custom-rounded-class',
    expanded: 'custom-expanded-class',
    disabled: 'custom-disabled-class',
    region: 'custom-region-class',
  };

  const customClassesDetails = {
    root: 'faq__details',
    rounded: 'custom-rounded-class',
    expanded: 'custom-expanded-class',
    disabled: 'custom-disabled-class',
    region: 'custom-region-class',
  };

 

  const customClassesSummary = {
    root: 'faq__summary',
    rounded: 'custom-rounded-class',
    expanded: 'custom-expanded-class',
    disabled: 'custom-disabled-class',
    region: 'custom-region-class',
    
  }

  const renderAccordion = (data) => {
    return data.map((item, i) => {
      const {question, answer} = item;

      return (
          <Accordion  
            key={i}
            classes={customClassesAccordion}
            >
            <AccordionSummary
              classes={customClassesSummary}
              expandIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="23" viewBox="0 0 26 23" fill="none">
                  <path d="M13 23L0.00961882 0.499999L25.9904 0.500002L13 23Z" fill="url(#paint0_linear_72_2932)"/>
                  <defs>
                    <linearGradient id="paint0_linear_72_2932" x1="13" y1="23" x2="13" y2="-7" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#FFC700"/>
                      <stop offset="1" stopColor="white"/>
                    </linearGradient>
                  </defs>
                </svg>
              }            
              aria-controls="panel1a-content"
              id="panel1a-header"

            >
              <span className='accourdion__icon'></span> 
              <span className='accourdian__text'>{question}</span>
              
            </AccordionSummary>
            {
              i !== 3 ?
                <AccordionDetails
                  classes={customClassesDetails}
                >
                  {answer}
                </AccordionDetails>
              :
              <AccordionDetails
                  classes={customClassesDetails}
              >
                <div>{answer[0]} <a className='accourdian__support-link' href={item.link}>{item.link}</a> {answer[1]}</div>
              </AccordionDetails>
            }
          </Accordion>
      )
    })
  }

  return (
    <>
      <a id="faq"></a>
      <section className="faq ">

        <img src={stars} alt="vak" className='faq__bg'/>
        
      <img src={leftPink} alt="left pink" className='faq__left-pink' />

      <img src={cloud} alt="left pink" className='faq__cloud' />
      <img src={light} alt="light" className='faq__light' />
      

      <div className="faq__container">
          <h2 className="faq__title">Частые вопросы</h2>

          <div className="faq__accourdings">
            {renderAccordion(data)}
          </div>

          <a 
            href="https://t.me/kali_shankar_support" 
            target="_blank"  
            rel="noopener noreferrer" 
            className="faq__button">Написать в поддержку</a>
      </div>
      </section>
    </>
    
  )
}

export default Faq;