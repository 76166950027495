

import firstBg from '../../image/first/first_bg.webp';
import yelow from '../../image/you-win/light-right.webp';
import petacles from '../../image/you-win/pectograma.webp';

import cloudLeft from '../../image/main/cloud-left.webp';
import cloudRight from '../../image/main/cloud-right.webp';
import cloudRightTwo from '../../image/for-whom/front-cloud.webp';

import leftPink from '../../image/footer/left-pink.webp';

import './FirstDay.scss';

const data = [
  {
    title: '-1410$ на все доступные курсы Кали Шанкара',
    color: '#FFC700',
    description: 'Astrodemy, Накшатры, Astrodemy Plus, Астропсихология, Транзитная Астрология, Тени в Бизнесе, Уровень Женской Энергии.',
    oldPrice: '3800$',
    price: '2390$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: 'https://secure.wayforpay.com/button/b6e8d82bf13da'
  },

  {
    title: '-75$ на лекцию Тени в Бизнесе',
    color: '#fff',
    description: 'Развернутый урок по мотивам выступления Кали Шанкара на Grand Business Event  в Нью Йорке для бизнесменов.',
    oldPrice: '250$',
    price: '175$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: 'https://secure.wayforpay.com/button/b53897c4be1da'
  },

  {
    title: '-522$ на базовый курс Astrodemy',
    color: '#FFC700',
    description: 'Более 118 часов лекций, закрытое комьюнити с кураторами и студентами, Курс Накшатры в подарок',
    oldPrice: '1490$',
    price: '968$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: 'https://secure.wayforpay.com/button/b30e4990f6677'
  },

  {
    title: '-360$ на продвинутый курс Astrodemy Plus',
    color: '#fff',
    description: 'Продвинутый живой курс по Астрологии Джйотиш. Купить его можно только через поддержку! Делай скриншот и отправляй его нам в Telegram, чтобы не потерять свою скидку!',
    oldPrice: '1200$',
    price: '840$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: ''
  },

  {
    title: '-365$ на Астропсихологию, Транзитную Астрологию и лекцию Тени в Бизнесе',
    color: '#fff',
    description: 'Три дополнительных продукта, которые доступны к прохождению без базового курса Astrodemy',
    oldPrice: '900$',
    price: '535$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: 'https://secure.wayforpay.com/button/b9d4c46b2c7ca'
  },

  {
    title: '-997$ на два курса: Astrodemy и Astrodemy Plus',
    color: '#FFC700',
    description: 'Лучшее предложение, чтобы изучить Астрологию до уровня профи и зарабатывать на консультациях',
    oldPrice: '2690$',
    price: '1693$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: 'https://secure.wayforpay.com/button/b48be65f48cb1'
  },

  {
    title: '-650$ на Astrodemy Plus, Транзитную Астрологию, Астропсихологию и лекцию тени в Бизнесе',
    color: '#fff',
    description: 'Этот пакет продается только через поддержку! Делай скриншот и отправляй его нам в Telegram, чтобы не потерять свою скидку!',
    oldPrice: '2100$',
    price: '1450$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: ''
  },

  {
    title: '-115$ на курс Астропсихология',
    color: '#FFC700',
    description: 'Курс для астрологов, психологов, сексологов и всех, кто кто хочет разобраться в Астрологии, как инструменте Психологии.',
    oldPrice: '325$',
    price: '210$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: 'https://secure.wayforpay.com/button/bd736347cc5c9'
  },

  {
    title: '-505$ на Astrodemy Plus и Транзитную Астрологию',
    color: '#FFC700',
    description: 'Этот пакет продается только через поддержку! Делай скриншот и отправляй его нам в Telegram, чтобы не потерять свою скидку!',
    oldPrice: '1525$',
    price: '1020$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: ''
  },

  {
    title: '-100$ на курс Транзитная Астрология',
    color: '#fff',
    description: 'Разобравшись с транзитами, вы будете знать, какая планета и на какую сферу жизни оказывает влияние. И сможете “подстелить соломки” в сложные периоды.',
    oldPrice: '325$',
    price: '225$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: 'https://secure.wayforpay.com/button/b014540874d04'
  },

  {
    title: '-380$ на Astrodemy Plus и АстроПсихологию',
    color: '#FFC700',
    description: 'Этот пакет продается только через поддержку! Делай скриншот и отправляй его нам в Telegram, чтобы не потерять свою скидку!',
    oldPrice: '1525$',
    price: '1145$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: ''
  },

  {
    title: '-475$ на Astrodemy Plus и лекцию Тени в Бизнесе',
    color: '#fff',
    description: 'Этот пакет продается только через поддержку! Делай скриншот и отправляй его нам в Telegram, чтобы не потерять свою скидку!',
    oldPrice: '1450$',
    price: '975$',
    telegram: 'https://t.me/kali_shankar_support',
    linkBuy: ''
  },
  
]

const FirstDay = () => {
  const renderCard = (data) => {
    return data.map(item => {
      const {title, color, description, oldPrice, price, telegram, linkBuy} = item;

      return linkBuy.length > 0 ?
      (
        <li className='first__item item' key={title}>
          <h2 className="item__title" style={{color: color}}>{title}</h2>
          <p className="item__description">{description}</p>
          <span className='item__old-price'>{oldPrice}</span>
          <a href={linkBuy} target="_blank" rel="noopener noreferrer" className='item__new-price'>{price}</a>

          <p className='item__problem'>проблемы с оплатой?</p>
          <a href={telegram} target="_blank" rel="noopener noreferrer" className='item__support'>Пишите + в нашу поддержку в Telegram!</a>
        </li>
      )
      :
      <li className='first__item item' key={title}>
        <h2 className="item__title" style={{color: color}}>{title}</h2>
        <p className="item__description teleg">{description}</p>
        <span className='item__old-price teleg'>{oldPrice}</span>
        <span className='item__new-price teleg'>{price}</span>
        <a href={telegram} target="_blank" rel="noopener noreferrer" className='item__support-teleg'>написать в поддержку</a>

        <p className='item__problem'>ДЕЛАЙ СКРИНШОТ</p>
        <a href={telegram} target="_blank" rel="noopener noreferrer" className='item__support'>и отправляй его нам в поддержку</a>
      </li>
    })
  }

  return (
    <section className='first'>
      
      <img src={firstBg} alt="first" className='first__bg' />
      <img src={yelow} alt="yelow" className='first__yelow' />
      <div className="first__blur"></div>

      <img src={petacles} alt="pentacles" className='first__pentacles' />

      <img src={leftPink} alt="pink" className='first__left-pink' />

      <img src={cloudLeft} alt="front" className='first__front-front'/>
      <img src={cloudLeft} alt="front" className='first__front-left-top'/>
      <img src={cloudRight} alt="front" className='first__front-right-top' />
      <img src={cloudLeft} alt="front" className='first__front-left-bottom'/>
      <img src={cloudRightTwo} alt="front" className='first__front-right-bottom'/>

      <div className="first__blur-bottom"></div>

      <div className="first__container">

        <h2 className='first__title'>
          ЧЕРНАЯ ПЯТНИЦА В KALI SHANKAR ASTRODEMY
          {/* ТОЛЬКО 1 <span>ДЕНЬ!</span> ЗАКРЫТАЯ ПРЕДПРОДАЖА К ЧЕРНОЙ ПЯТНИЦЕ */}
        </h2>

        <p className='first__subtitle'>
          ХВАТАЙ ЗНАНИЯ ПРЯМО СЕЙЧАС И БУДЕШЬ БЛАГОДАРИТЬ СЕБЯ ЗА ЭТО РЕШЕНИЕ!  
        </p>

        <ul className='first__list'>
          
          {renderCard(data)}
          

        </ul>

        <p className='first__text'>
          {/* 21 ноября в 23:59 по Киеву предпродажа закончится. Скидки Черной Пятницы станут меньше. */}
          30 ноября в 23:59 по Киеву предложения будут закрыты. И все цены вернуться на свои места.
        </p>

        <a href="https://t.me/kali_shankar_support" target="_blank" rel="noopener noreferrer" className='first__write'>Написать в поддержку</a>
      </div>
    </section>
  )
}

export default FirstDay;