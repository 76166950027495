import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import SendFormService from '../../service/SendFormService';

import telegram from '../../image/footer/telegram.webp';
import youtube from '../../image/footer/youtube.webp';

import insta from '../../image/footer/insta.webp';

import logo from '../../image/logo.webp';

import footerBg from '../../image/footer/footer-bg.webp';
import cloudButtom from '../../image/footer/cloud-footer.webp';

import stars from '../../image/for-whom/for-who-background.webp';


import './Footer.scss';


const Footer = () => {
  

  return (
    <footer className='footer '>

      <div className="footer__blur-top"></div>
      <img src={footerBg} alt="footer bg" className='footer__pentogram' />
      <img src={cloudButtom} alt="cloud footer" className='footer__cloud' />
     
      <div className="footer__bg"></div>
      <img src={stars} alt="" className="footer__stars" />

      <ul className="footer__list">
        <li className="footer__item item">
          <img src={logo} alt="logo" className='item__logo' />
          <span>ФОП Небоженко Євгеній Вікторович</span>
          <span>ЄДРПОУ 3057322258 IBAN</span>
          <span>UA103220010000026001320074367</span>
        </li>
        <li className="footer__item item">
          <h3 className='item__header'>Навигация по сайту</h3>

          <a href="#forwhom" className="item__navigation">Для кого</a>
          <a href="#reviews" className="item__navigation">Отзывы</a>
          <a href="#faq" className="item__navigation">FAQ</a>
          <a href="#contact" className="item__navigation">Контакты</a>
        </li>
        <li className="footer__item item">
          <h3 className='item__header'>Правила и политики:</h3>

          <a 
            href="https://docs.google.com/document/d/1sX3HSDzWuFwOQQ7aAr2YXzZpzGi9rOy1TOnUNfpPrxk/edit" 
            target="_blank"  
            rel="noopener noreferrer" 
            className="item__navigation">Политика конфендициальности</a>
          <a 
            href="https://docs.google.com/document/d/1B9V1hkO6rcwmjT651Gdy3hXb6cZErt5dZDjzE3f9fTc/edit" 
            target="_blank"  
            rel="noopener noreferrer" 
            className="item__navigation">Договор оферты</a>

        </li>
        <li className="footer__item item">
          <h3 className='item__header'>Мы в социальных сетях</h3>

          <div className="item__social">
            <a href="https://www.youtube.com/@kali_shankar" target="_blank"  rel="noopener noreferrer">
              <img src={youtube} alt="youtube" />
            </a>

            <a href="https://t.me/KaliShankarChannel" target="_blank" rel="noopener noreferrer">
              <img src={telegram} alt="telegram" />
            </a>

            <a href="https://www.instagram.com/kali_shankar/" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="instagram" />
            </a>
          </div>
          
        </li>
      </ul>

      {/* <a href="https://newstart-digital.com.ua/" className="footer__creator">Created by New Start Digital</a> */}
    </footer>
  )
}

export default Footer;