
import { useEffect, useState } from 'react';


import { Sling as Hamburger } from 'hamburger-react'

import logo from '../../image/logo.webp';

import cloudTop from '../../image/main/cloud-top.webp';

import './Header.scss';

const Header = ({modal}) => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    showMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = ''
  }, [showMenu])

  const checkedMenu = () => {
    setShowMenu(false);
  }

  return (
    <>
      <header className='header desktop'>
        
        <nav className='header__nav nav'>
          <img src={logo} alt="logo"  className='nav__logo'/>
          <ul className="nav__list">
            <li className="nav__item">
              <a href="#forwhom" className="nav__link">
                Для кого 
              </a>
            </li>
            <li className="nav__item">
              <a href="#reviews" className="nav__link">
                Отзывы
              </a>
            </li>
            <li className="nav__item">
              <a href="#faq" className="nav__link">
                FAQ
              </a>
            </li>
            <li className="nav__item">
              <a href="#contact" className="nav__link">
                Контакты
              </a>
            </li>
          </ul>
        </nav>

      </header>

      <header className='header header__mobile' style={showMenu ? {backgroundColor: '#200929'} : {}}>
        <ul className="header__order header__order-mobile order__list">
          <li className="order__item">
            <a href="#" className='order__link'>
              <img src={logo} alt="logo" />
            </a>
          </li>
          <li className='order__item'>
            <Hamburger toggled={showMenu} color='#fff' toggle={setShowMenu} />
          </li>
        </ul>

        {showMenu ? (
          <nav className='header__nav nav-mobile-menu  nav' >
            
            <ul className="nav__list">
              <li className="nav__item">
                <a href="#for-whom" onClick={checkedMenu} className="nav__link">
                  Для кого 
                </a>
              </li>
              <li className="nav__item">
                <a href="#reviews" onClick={checkedMenu} className="nav__link">
                  Отзывы
                </a>
              </li>
              <li className="nav__item">
                <a href="#faq" onClick={checkedMenu} className="nav__link">
                  FAQ
                </a>
              </li>
              <li className="nav__item">
                <a href="#contact" onClick={checkedMenu} className="nav__link">
                  Контакты
                </a>
              </li>
            </ul>
          </nav>
          )
          :
          null
        }   
          

        
      </header>
    </>
  )
}

export default Header;