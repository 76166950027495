import Countdown from 'react-countdown';

import pentakles from '../../image/counter/pentacles.webp';
import redLight from '../../image/faq/red-light.webp';

import './Timer.scss';

const Timer = () => {
  const targetDate = new Date('2023-12-01T00:00:00');
  const renderer = ({days, hours, minutes, seconds, completed }) => {
    

    if (completed) {
      return <span>{hours}:{minutes}:{seconds}</span>;
    } else {
      return (
        <div className='countdown'>
          <div className="countdown__item day">
            0{days}
            <span>дней</span>
          </div>
          <div className="countdown__item hour">
            {hours}
            <span>часов</span>  
          </div>
          <div className="countdown__item minutes">
            {minutes}
            <span>минут</span>
          </div>
          <div className="countdown__item seconds">
            {seconds}
            <span>секунд</span>
          </div>
        </div>
      );


    }
  };

  return (
    <section className="timer">
      
      <div className="timer__bg"></div>
      <div className="timer__blur-top"></div>
      <img src={pentakles} alt="pentakles" className='timer__pentacles' />
      <img src={redLight} alt="red light" className='timer__red-light' />

      <div className="timer__blur-bottom"></div>

      <div className="timer__container">
        <h2 className="timer__title">
          ЧЕРНАЯ ПЯТНИЦА
          <span>в Kali Shankar Astrodemy</span>
        </h2>
        <p className="timer__subtitle">будет закрыта через:</p>

        <div className="timer__countdown">
          <Countdown
            date={targetDate}
            renderer={renderer}
          />
        </div>

        <p className="timer__shance">Не упусти свой шанс!</p>
        {/* <a href="#" className="timer__button">Крутить колесо</a> */}

        <p className="timer__text">Начинай учиться со скидкой прямо сейчас, а не дороже и когда-то потом.</p>
      </div>
    </section>
  )
}

export default Timer;