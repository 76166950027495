
import palanet from '../../image/for-whom/planet.webp';
import stars_bg from '../../image/for-whom/for-who-background.webp';
import mentor from '../../image/for-whom/mentor.webp';
import blackTop from '../../image/for-whom/black-top.webp';
import blackRighttwo from '../../image/for-whom/black__right.webp';
// import leftPink from '../../image/footer/left-pink.webp';
// import rightPink from '../../image/footer/left-pink.webp';


import blackRight from '../../image/you-win/black-left.webp';

import './ForWhom.scss';

const ForWhom = () => {

  const data = [
    {
      title: 'для каждого',
      index: '01',
      items: [
        'кто хочет понять свое Предназначение и разобраться, как Астрология влияет на его жизнь',
        'кто берет на себя ответственность за свою жизнь',
        'кто готов работать над собой, а не искать волшебную таблетку'
      ]
    },
    {
      title: 'для практикующих астрологов',
      index: '03',
      items: [
        'которые хотят повысить свою квалификацию, получить новые знания и ещё эффективнее помогать людям',
      ]
    },
    {
      title: 'для будущих астрологов',
      index: '02',
      items: [
        'которые желают изучать всё в одном месте и начать практику',
        'консультирование после прохождения курса Astrodemy',
      ]
    },
    {
      title: 'для специалистов из смежных сфер',
      index: '04',
      items: [
        'психологов, психоаналитиков, коучей, социологов, сексологов, которые хотят получить знания по астрологии и быть сильнее своих конкурентов в работе',
      ]
    }
  ];

  const renderItem = (data) => {
    return data.map(item => {
      const items = (items) => {
        return items.map(item => {
          return <p className='for-whom__item-text' key={item}><span></span>{item}</p>
        })
      }

      return (
        <li className='for-whom__item' key={item.index}>
          <div className="for-whom__item-header">
            <span className="for-whom__item-header-index">{item.index}</span>
            <h3 className="for-whom__item-header-title">{item.title}</h3>
          </div>
          {items(item.items)}
        </li>
      )
    })
  }

  return (
   <>
      
      <section className='for-whom'>

        
        
        <img src={palanet} className='for-whom__planet' alt="planet" />
        <img src={stars_bg} className='for-whom__stars-bg' alt="planet" />
        <div className="for-whom__container">
          <img src={mentor} alt="mentor" className='for-whom__mentor-container' />
          <img src={blackRighttwo} alt="mentor" className='for-whom__black-right-two-container' />
          <h2 className="for-whom__title">для кого Обучение Кали Шанкара:</h2>
          <ul className='for-whom__list'>
            {renderItem(data)}
          </ul>

          
        </div>

        <img src={blackRight} alt="black-right" className='for-whom__black-right' />

        
        <img src={mentor} alt="mentor" className='for-whom__mentor' />
        <img src={blackRighttwo} alt="mentor" className='for-whom__black-right-two' />
        {/* <img src={leftPink} alt="mentor" className='for-whom__left-pink' /> */}
        {/* <img src={rightPink} alt="mentor" className='for-whom__right-pink' /> */}
        <img src={blackTop} alt="mentor" className='for-whom__black-top' />
      </section>
   </>
  )
}

export default ForWhom;