
import saltanat from '../../image/revies/saltanat.webp';
import sheremeteva from '../../image/revies/sheremeteva.webp';
import lada from '../../image/revies/lada-berg.webp';
import kaoustina from '../../image/revies/kapustina.webp';
import ira from '../../image/revies/ira.webp';
import roshkina from '../../image/revies/roshkina.webp';

import blick from '../../image/you-win/left_blick.webp';
import frontCloud from '../../image/for-whom/front-cloud.webp';


import './Rewies.scss';

const data = [
  {
    src: saltanat,
    description: 'Первый раз я Вас прочитала 2017 году. Были очень тяжелые дни - предательство бывшего мужа, болезнь родного братишки. Я просто думала что не выживу. Читаю вас в инсте, становится лучше, нашла первое на ютубе видео и слушала, переслушивала. Начала появляться энергия, а дальше все исправлять и жить счастливо. Все мои желания на марафоне желаний, учиться у Bac. Все сбылось - эмоция, вдохновение, разговоры с Вами - повторить и уже есть выстроенная синаптическая связь🙏❤️❤️❤️',
    instagram: '@saltanat_astroenergy',
    name: 'Салтанат Узенбаева'
  },
  {
    src: sheremeteva,
    description: 'Шанкара! Ваше присутствие в моей жизни огромным светом пролилось. В моменты тьмы, когда не ясно откуда искать веры, в эти мгновения светом приходили вы! Это была и консультация, и совет, и видения мира, и знания, много знаний, очень много знаний. Теперь эти знания моя опора. Мой компас в понимании себя и мира. Неизменно люблю и благодарю',
    instagram: '@sherienadin',
    name: 'Надежда Шереметьева'
  },
  {
    src: lada,
    description: 'Выражаю благодарность Вам Учитель, и всей вашей команде. Цельно, фундаментально, глубоко и основательно, многослойно сформулированные и раскрытые знания. За веру в царское в каждом из нас отдельная благодарность. Вы обращаетесь к самым глубоким ценностям, где рождается сильная мотивация не просто жить, а жить осознанно и качественно. У меня расправляются крылья! 🙏❤️✨👌🖤',
    instagram: 'lada.bereg',
    name: '@lada.bereg'
  },
  {
    src: kaoustina,
    description: 'Я Благодарю Вас Учитель за знания! Это очень широкие знания, я как будто путешествую по странам, эпохам, узнаю о множестве интересных выдающихся людей. Многое стала понимать про себя, людей вокруг, про отношения.Я расту как личность, развиваю кругозор и взгляд на жизнь.Благодарю от неба до земли, что Вы щедро делитесь этим с нами🙏💐',
    instagram: '@vetakap',
    name: 'Виолетта Капустина'
  },
  {
    src: ira,
    description: 'В феврале 2017 я лежала в кардиологии с неутешительным диагнозом типа «возможно вы выживите» и понимала, что особо жить незачем. Семьи нет, работа унылая, я ничего не создаю, и миру как бы не нужна. Печально, но мне было все равно. В это время девочка в Инста выложила часть вашей лекции на о.Бали и я подумала: эхх, как это интересно, жаль уже не успеть. Ровно 12.04.2017 состоялась моя консультация по Предназначению. Я выжила и у менз появилось время и смысл. Сказать благодарю это ничего не сказать.Я благодарю вселенную за то, что мне встретился такой Учитель, и ту девочку в Инстаграмм. Одно действие малознакомого человека будто бы открыло окно в космос﻿﻿. С днём рожденья Учитель. Низкий поклон вам за все знания, к которым вы открываете окно',
    instagram: 'Ирина',
    name: '@ursha25'
  },
  {
    src: roshkina,
    description: 'Даже не берусь описать степень моего восхищения Вами Учитель! Для меня Вы прежде всего Учитель Астрологии, т.к. это мой стойкий многолетний интерес.Но, больше мне импонирует, что Вы не просто лучший Астролог и все.Ваша эрудиция, разносторонность и человеческие качества поражают и безмерно вдохновляют!',
    instagram: '@roschynaoksana',
    name: 'Oksana Roschina'
  },
]

const Rewies = () => {

  const renderRevies = (data) => {
    return data.map(item => {
      const {src, description, instagram, name} = item;

      return (
        <li className='revies__item item' key={name}>
          <p className="item__text">
            <span>{description}</span>
          </p>
          <div className="item__container">
            <img src={src} alt={name} />

            <div className="item__info">
              <span className='item__name'>{name}</span>
              <span className='item__insta'>{instagram}</span>
            </div>
          </div>
        </li>
      )
    })
  }

  return (
    <section className='revies'>
      <div className="revies__bg"></div>
      <img src={frontCloud} alt="mentor" className='revies__front-cloud' />
      
      <div className="revies__container">
        <img src={blick} alt="blick" className='revies__blick' />
        <h2 className='revies__title'>
          Как обучение у Кали Шанкара помогает делать свою жизнь лучше и решать существующие проблемы:
        </h2>

        <ul className="revies__list">
          {renderRevies(data)}
        </ul>
      </div>
      
    </section>
  )
}

export default Rewies;